import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import Header from './Navigattion/Header';
import Footer from './Navigattion/Footer';
import Fonctionnement from './Components/Fonctionnement';
import Faq from './Components/Faq';
import Slider from './Components/Slider';
import LogoCompagnie from './Components/LogoCompagnie';
import Notice from './Components/Notice';
import Notice1 from './Components/Notice1';
import Notice2 from './Components/Notice2';
import Section1 from './Components/Section1';
import Section2 from './Components/Section2';
import { Seo } from '../helpers/Seo';

class Home extends Component {
  render() {
    return (
      <div>
        <Seo
          title="Pret personnel 100 approuvé sans document | Good Good Finance Canada."
          description="Good Good Finance is a tool for analyzing and comparing many alternative financing solutions for the territory of Canada.
                We list different online financing institutions to determine which one can offer you the best rate and most importantly the fastest approval!
                We are committed to directing you to the most appropriate loan for your needs, in complete security.
                *All the institutions we compare must be registered with the Canada Enterprise Register and hold all the permits and accreditations required by the Consumer Protection Office."
          type="webapp"
          name="Good Good Finance"
        />

        <Header />
        <Slider />
        <main id="main">
          <Fonctionnement />
          <Faq />
          <Notice />
          <Section1 />
          <Notice1 />
          <Section2 />
          <Notice2 />
          <LogoCompagnie />
        </main>
        <Footer />
      </div>
    );
  }
}
export default Home;

